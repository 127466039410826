import {
  type CreateTRPCClientOptions,
  httpBatchLink,
  loggerLink,
} from '@trpc/client'

import { transformer } from '@mntn-dev/utilities'

import type { AppRouter } from '@mntn-dev/api'
import { route } from '@mntn-dev/app-routing'
import { getClientRouteUrl } from '~/utils/client/get-url.ts'
import { errorLink } from './trpc-links.ts'

export const trpcClientConfig: CreateTRPCClientOptions<AppRouter> = {
  links: [
    errorLink({
      onError: (error) => {
        if (error.message === 'expired_session') {
          window.location.href = route('/auth/logout').toRelativeUrl()
        }
      },
    }),
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === 'development' ||
        (opts.direction === 'down' && opts.result instanceof Error),
    }),
    httpBatchLink({
      transformer,
      url: getClientRouteUrl('api', 'trpc'),
    }),
  ],
}
